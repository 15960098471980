@import "custom";
@import "~bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap');

@function pow($number, $exponent) {
  $value: 1;

  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $value: $value * $number;
    }
  }

  @return $value;
}

/*
 * Globals
 */
$cube-edge: 200px;

/*
 * Base structure
 */

body {
  // perspective: 25em;
  // -webkit-perspective: 25em;
  // overflow: hidden;
  user-select: none;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

.card-text {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .card-text {
    min-height: 0;
  }
}

[class*="cube"] {
  position: absolute;
}

.cube {
  width: $cube-edge;
  height: $cube-edge;
  transform-style: preserve-3d;
  font-family: 'Rubik Mono One', sans-serif;
  font-size: 90px;
  animation: ani 12s ease-in-out infinite;
  animation-delay: 2s;
  pointer-events: none;
  position: relative;

  &__face {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $black;
    background-color: $light;
    backface-visibility: hidden;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 0 10px;
    //opacity: .3;
    transform: rotate3d(var(--ci, 0), var(--cj, 1), 0, var(--ca, 0deg)) translateZ(0.5 * $cube-edge);

    span {
      transform: rotate(var(--cr, 0));
      margin-left: var(--cm, 0);
    }

    &:nth-child(n + 5) {
      --ci: 1;
      --cj: 0;
    }

    &:nth-child(5)
    {
      --cr: 180deg;
      // --cm: -8px;
    }

    &:nth-child(2)
    {
      --cr: 90deg;
      --cm: -8px;
    }

    &:nth-child(4)
    {
      --cr: 90deg;
      --cm: -8px;
    }

    @for $f from 1 to 6 {
      &:nth-child(#{$f + 1}) {
        @if $f < 4 {
          --ca: #{$f * 90deg};
        }
        @else {
          $pow: pow(-1, $f);
          --ca: #{$pow * 90deg};
        }
      }
    }
  }
}

@keyframes ani {
  to {
    transform: rotateY(1turn) rotateX(1turn);
  }
}

.no-animation {
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}